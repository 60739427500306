import { getNumberMonthByName } from "@/helpers/formatDate";
import { Service } from "models/Service";

export class Holiday extends Service {
  constructor() {
    let year = new Date().getFullYear();
    super({
      adsPath: "servicios/feriados",
      meta: {
        description: `Calendario con todos los días no laborables, fines de semanas largos y días festivos. Mes a mes todos los feriados ${year} de Argentina en Clarín.`,
        keywords: `Feriados, Argentina, fin de semana largo, asueto, ${year}`,
        title: `Feriados ${year}: calendario con todos los días no laborables - Clarín`,
        url: `feriados`, 
        extra: [],
      },
      section: "feriados",
      sectionAds: "feriados",
      sectionName: "Feriados",
      template: "HolidayPublic",
      title: `Feriados ${year}: calendario con todos los días no laborables - Clarín`,
      slug: "feriados",
      titleHeader: `Feriados nacionales y días no laborales del ${year} en Argentina`,
      breadcrumb: [
        {
          title: `Feriados nacionales ${year} en Argentina`,
          url: "/feriados",
        }
      ] 
    });
  }

  parseData = async (query) => {
    const slug= this.getSlug()
    const notes = await this.fetchData(`${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/latest/?type=lilanews&tag=${slug}&limit=6`);
    let year = false;
    let month = false;
    if (query[1]) {
      year = query[1];
      
      this.setBreadcrumb( [
        {
          title: `Feriados nacionales ${year} en Argentina`,
          url: "/feriados",
        }
      ]);
      this.setTitleHeader(`Feriados nacionales y días no laborales del ${year} en Argentina`);
      this.setTitle(`Feriados ${year} en Argentina: cuándo es el próximo feriado nacional`);
      this.setMeta(
        this.parseMeta({
          description: `Calendario de feriados ${year} en Argentina: días asuetos, no laborables, trasladables, fin de semana largo y feriados puentes del 2025 y 2026 en Clarín.`,
          keywords: `Feriados, Argentina, fin de semana largo, asueto, ${year}`,
          title: `Feriados ${year} en Argentina: cuándo es el próximo feriado nacional`,
          url: `feriados/${year}${query[2]?'/'+query[2]:""}`, 
          extra: [],
        })
      );
      if (query[2]) {
        month = getNumberMonthByName(query[2]);
        this.setSectionAds(query[2]);
        this.setTitle(`Feriados de ${query[2]} de ${query[1]} Argentina: días no laborables, fines de semana largo y fechas patrias`);
        this.setTitleHeader(`Feriados de ${query[2]} de ${query[1]}: ¿qué días son no laborables este mes?`);
        this.setMeta(
          this.parseMeta({
            description: `Conoce todos los feriados, días no laborables, fechas patrias, fin de semana largo de este mes de ${query[2]} en Argentina ${query[1]}.`,
            keywords: `feriados, ${query[2]}, Argentina, ${query[1]}, asueto, fecha patria, efemérides`,
            url: `feriados/${year}${query[2]?'/'+query[2]:""}`, 
          })
        );
      }
    } else {
      year = new Date().getFullYear();
    }
    const url_base= `${process.env.NEXT_PUBLIC_FRONTEND_API}contents/v1/holidays/`;
    const years = [parseInt(year) - 1, parseInt(year), parseInt(year) + 1];
    const yearBeforeFetch = !month ? await this.fetchData(`${url_base}${years[0]}`) : {};
    const yearBefore = yearBeforeFetch?.data ?  yearBeforeFetch.data : {};
    const yearGetFetch = await this.fetchData(`${url_base}${years[1]}`);
    const yearGet = yearGetFetch?.data ?  yearGetFetch.data : {};
    const yearNextFetch = !month ? await this.fetchData(`${url_base}${years[2]}`) : {};
    const yearNext = yearNextFetch?.data ?  yearNextFetch.data : {};
    yearBefore.pathYear = years[0];
    yearGet.pathYear = years[1];
    yearNext.pathYear = years[2];
    
    if (month !== false) {
      yearGet.pathMonth = month;
    }

    if (yearGet?.item?.holidays) {
      yearGet.item.holidays.forEach((holiday) => {
        if (holiday.transfer && holiday.day !== holiday.transfer) {
          yearGet.item.holidays.push({
            ...holiday,
            ["day"]: holiday.transfer,
            ["transfer"]: holiday.transfer,
            ["hidden"]: true,
          });
        }
      });
    }
    this.setData({
      holidaysData: [yearBefore, yearGet, yearNext],
      notes: notes?.data?.items ?? [],
    });
  };
}
