import { Service } from 'models/Service'

export class LandingApp extends Service {
  constructor() {
    super({
      adsPath: 'servicios/home',
      meta: {
        description: `LandingApp - Clarín.com`,
        keywords:
          'noticias, política, deportes, economía, internacionales, mundo, espectáculos, Argentina, sociedad, ciudades, policiales, internet, tecnología, infografías, fotos, videos, audios, multimedia, clima, claringrilla, humor, blogs, mapas, archivo, edición impresa, ',
        title: `LandingApp`,
        url: 'landingapp',
        extra: [],
      },
      /* section: 'humor',
      sectionName: 'Humor', */
      template: 'LandingApp',
      title: 'LandingApp',
      slug: 'landingapp',
      //titleHeader: 'LandingApp',
      breadcrumb: [],
    });
    this.setWidthFull(true);
  }
  
  parseData = async (query) => {
    this.setData({
      message: 'ok',
    });
  }
}
