import { CurrencyBond, Holiday, Lottery, Newsletter, RuralWeather, Weather, WesternAstrology, Searcher,Tags, GridGame,ChineseAstrology, Games, SportsCalendar, RadioLive, VideoDocumentary, FrequentlyAskedQuestions, Service, Dialogues, Joke, SiteMap as Mapa, TermsConditions, Rss, Movil, Elections, LandingEMQV, LandingDemocraciayDesarrollo, CorporateLanding, Olympics, AudioPlayer, LandingApp} from "models";

const listOfClass = {
    "buscador": Searcher,
    "claringrilla": GridGame,
    "clima": Weather,    
    "deportes": SportsCalendar,
    "dialogos": Dialogues,
    "economia": CurrencyBond,
    "feriados": Holiday,
    "horoscopo": WesternAstrology,
    "horoscopo-chino": ChineseAstrology,
    "humor": Joke,
    "landingapp": LandingApp,
    "juegos": Games,
    "loterias-y-quinielas": Lottery,
    "newsletter": Newsletter,
    "newsletters": Newsletter,
    "preguntas-frecuentes": FrequentlyAskedQuestions,
    "radio-mitre-vivo": RadioLive,
    "rural": RuralWeather,
    "temas": Tags,
    "videos-documentales-clarin": VideoDocumentary,
    "mapa-del-sitio": Mapa,
    "ayuda": TermsConditions,
    "rss.html": Rss,
    "movil.html" : Movil,
    "elecciones" : Elections,
    "Elmundoqueviene" : LandingEMQV,
    "DemocraciayDesarrollo" : LandingDemocraciayDesarrollo,
    "suscripciones" : CorporateLanding,
    "juegos-olimpicos" : Olympics,
    "playlist": AudioPlayer
}
export const getServiceData = async (query, menu) => {
    let service;
    if (listOfClass[query.match[0]]) {
        service = new listOfClass[query.match[0]];
    } else {
        service = new Service({});
    }
    await service.parseData(query.match, menu);
    return service;
}