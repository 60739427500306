export const getVideoLoopUrl = (urlLoop,video) => {
    if(urlLoop?.url) {
        return {    
            url: null,
            entityId: urlLoop.entityId,
            videoName: urlLoop.videoName
        };
    } 

    if(video?.entityId) {
        return {
            url: `https://cdn.jwplayer.com/v2/media/${video.entityId}/poster.mp4?width=640`,
            entityId: video.entityId

        }
    } 
}